export interface AccountInfo {
  gpAccountEmail: string;
  gpAccountId: string;
  gpAccountTenantId: string;
  languageType: string;
  tenantId: string;
  timeZone: number;
  timeZoneText: string;
  userAvatarUrl: string;
  userName: string;
  /** 是否跳过设置密码 */
  gpAccountPasswordIsskip: boolean;
  gpAccountSource: string;
}

export interface CheckResetToken {
  data: CheckResetTokenData;
  status: CheckResetTokenStatusEnum;
}

export interface CheckResetTokenData {
  accountId: string;
  redirectUrl: string;
  tenantId: string;
}

export enum CheckResetTokenStatusEnum {
  Success = "success",
  Failed = "failed",
}

export interface BiteClaims {
  accountId: string;
  deviceIdentity: string;
  deviceName: string;
  location: string;
  loginTime: Date;
  os: string;
  tenantId: string;
  times: Date;
  tokenType: string;
}

export enum VerifyType {
  ResetPassword = "RESETPASSWORD",
  TransOwner = "TRANSOWNER",
  DeleteWorkspace = "DELETEWORKSPACE",
}

export enum SetPasswordStatus {
  success = "success",
  failed = "Password update failed.",
  canNotContainPassword = "The password cannot contain the word password.",
  passwordIsTooShort = "Your password is too short.",
  passwordToRepeat = "Your new password must be different from your previous password.",
  passwordError = "The password cannot contain the word password.",
  passwordRulesNotExists = "passwordRulesNotExists",
  weakPassword = "weakPassword",
}
export interface RoleInfo {
  id: string;
  gpAccountId: string;
  permissionRoleId: string;
  gpRoleType: string;
  tenantId: string;
}
